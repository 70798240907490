window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');

import "inputmask";

//FilePond
window.FilePondPluginImagePreview = require('filepond-plugin-image-preview')
window.FilePondPluginFileValidateSize = require('filepond-plugin-image-validate-size')
window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type')
window.FilePond = require('filepond')
require('jquery-filepond/filepond.jquery')
import pt_BR from 'filepond/locale/pt-br.js';


//------------ Animao AOS -----------------

window.AOS = require('AOS');
AOS.init();

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------- ADMIN TELA --------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

$(document).ready(function () {

    //Back Button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    ///maskinput
    let cellphone = document.getElementById('cellphone');
    if(cellphone) Inputmask().mask(cellphone);

    //FilePond
    FilePond.setOptions(pt_BR);
    FilePond.setOptions('pt-br');
    $.fn.filepond.registerPlugin(FilePondPluginFileValidateSize,FilePondPluginImagePreview,FilePondPluginFileValidateType);
    $.fn.filepond.setOptions({
        acceptedFileTypes: 'image/*',
        maxFileSize: '1MB',
    });

    $('input[type="file"]').filepond({
        allowMultiple: false,
        allowDrop: true,
        allowBrowse: true,
        allowRemove: true,
        maxFiles: 1,
        allowImagePreview: true,
        allowFileSizeValidation: true,
        maxFileSize: '1MB',
        storeAsFile: true,
    });

    let thumb_edit = $('#thumbnail_edit').val();
    if(thumb_edit){
        $('#thumbnail')
            .filepond('addFile', ''+thumb_edit+'')
            .then(function (file) {});
    }

    let thumb_author_edit = $('#author_thumb_edit').val();
    if(thumb_author_edit){
        $('#author_thumb')
            .filepond('addFile', ''+thumb_author_edit+'')
            .then(function (file) {});
    }

    let thumbnail_user_edit = $('#thumbnail_user_edit').val();
    if(thumbnail_user_edit){
        $('#thumbnail')
            .filepond('addFile', ''+thumbnail_user_edit+'')
            .then(function (file) {});
    }

    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
    $('.toast').toast('show');


    if($('table').length > 0){
        $('table').DataTable({
            "autoWidth": true,
            "info": true,
            "ordering": true,
            "pagingType": "full_numbers",
            "language": {
                "lengthMenu": "Mostrar _MENU_ registro por página",
                "zeroRecords": "Nada encontrado - Desculpe",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "Nenhum registro encontrado",
                "infoFiltered": "(Filtrado de _MAX_ registros totais)",
                "search": "Busca",
                "paginate": {
                    "next": "<i class='fa fa-angle-right' aria-hidden='true'></i>",
                    "last": "<i class='fa fa-angle-double-right' aria-hidden='true'></i>",
                    "first": "<i class='fa fa-angle-double-left' aria-hidden='true'></i>",
                    "previous": "<i class='fa fa-angle-left' aria-hidden='true'></i>"
                }
            },
        });

    }

});

/// -------------------------------------------------Sidebar------------------------------------------------------------
$("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
});

// ------------------------------------------------Password View----------------------------------------------------
$("#show_hide_password").on('click', function(event) {
    event.preventDefault();
    if($('#password').attr("type") === "text"){
        $('#password').attr('type', 'password');
        $('#password-confirm').attr('type', 'password');
        $('#show_hide_password i').removeClass( "fa-eye" );
        $('#show_hide_password i').addClass( "fa-eye-slash" );
    }else if($('#password').attr("type") === "password"){
        $('#password').attr('type', 'text');
        $('#password-confirm').attr('type', 'text');
        $('#show_hide_password i').removeClass( "fa-eye-slash" );
        $('#show_hide_password i').addClass( "fa-eye" );
    }
});




